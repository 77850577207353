import React from "react";
import "./MeetOurTeam.scss";
import MeetOurTeamMember from "./MeetOurTeamMember";

function MeetOurTeam() {
  return (
    <section id="MeetOurTeam">
      <div className="mot-container">
        <div className="mot-title-container">
          <h3>
            Meet our<strong className="highlight-bg-blue">Team</strong>
          </h3>
        </div>
        <div className="mot-team">
          <div className="not-col-1">
            <MeetOurTeamMember
              name="Pam Progg"
              color="green"
              intro="Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet"
            />
            <MeetOurTeamMember
              name="Marc"
              color="yellow"
              intro="Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet"
            />
          </div>
          <div className="not-col-2">
            <MeetOurTeamMember
              name="Anton Robot"
              color="purple"
              intro="Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet"
            />
            <MeetOurTeamMember
              name="Lea"
              color="pink"
              intro="Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet"
            />
            <MeetOurTeamMember
              name="Mike"
              color="purple"
              intro="Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet"
            />
          </div>
          <div className="not-col-3">
            <MeetOurTeamMember
              name="Rico Coder"
              color="yellow"
              intro="Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet"
            />
            <MeetOurTeamMember
              name="Julia"
              color="green"
              intro="Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default MeetOurTeam;
