import * as React from "react";

function WeOfferIconsDesktop() {
  return (
    <svg width={888.189} height={733.601} viewBox="0 0 888.189 733.601">
      <defs>
        <style>
          {
            ".woid__a{fill:#7d1cc9}.woid__b,.woid__j{fill:#ffea6c}.woid__d,.woid__f{fill:#7cdd8a}.woid__d,.woid__j{font-size:28px;font-family:Gilroy-ExtraBold,Gilroy;font-weight:800}.woid__g{fill:#960863}.woid__h{fill:#ef2996}.woid__k{fill:#6498ed}.woid__l{fill:#99e7ff}"
          }
        </style>
      </defs>
      <path
        className="woid__a"
        d="M740.318 648.413c.258.009.512 0 .769.009l1.452-39.872c-.259 0-.51.017-.77.007-17.743-.646-30.926-13.973-30.221-33.356s14.823-31.715 32.567-31.069c17.58.64 30.662 13.736 30.217 32.827l40.958 1.491c1.193-40.428-29.769-72.72-69.724-74.175-40.13-1.461-73.485 28.76-74.965 69.435s29.588 73.242 69.717 74.703z"
      />
      <path
        className="woid__b"
        d="M724.781 589.397l-2.234-1.162c-28.125-14.627-40.935-36.69-40.935-51v-42.746h4.842c18.134 0 27.324-2.445 35.119-9.341l3.208-2.838 3.208 2.838c7.8 6.9 16.985 9.341 35.119 9.341h4.841v42.746c0 14.309-12.809 36.373-40.934 51zm-33.486-85.29v33.128c0 9.3 8.969 27.677 33.486 41.213 24.517-13.536 33.486-31.91 33.486-41.213v-33.128c-15.494-.433-25.278-3.08-33.486-9.112-8.208 6.032-17.992 8.679-33.486 9.112z"
      />
      <path
        d="M744.38 527.079l-16.487 16.486a11.945 11.945 0 01-16.872 0l-5.839-5.839 6.912-6.912 5.839 5.839a2.158 2.158 0 003.048 0l16.486-16.486z"
        fill="#69ed82"
      />
      <text className="woid__d" transform="translate(752.846 610.661)">
        <tspan x={0} y={0}>
          {"Adult and"}
        </tspan>
        <tspan x={0} y={32.888}>
          {"Bully Free"}
        </tspan>
      </text>
      <path
        d="M391.173 640.746c0-.258-.017-.512-.02-.769l-39.9.057c.006.259.035.509.036.769.025 17.755-12.794 31.433-32.19 31.461s-32.251-13.618-32.281-31.371c-.026-17.592 12.566-31.16 31.661-31.437l-.059-40.986c-40.444.337-71.543 32.5-71.485 72.48.057 40.156 31.518 72.344 72.22 72.286s72.075-32.338 72.018-72.49z"
        fill="#d81e8d"
      />
      <path
        className="woid__f"
        d="M315.598 726.703l-12.664-5.56-6.009 12.458-21.648-58.609 18.673-6.9z"
      />
      <path
        className="woid__f"
        d="M245.256 726.703l12.664-5.56 6.009 12.458 21.648-58.609-18.673-6.9z"
      />
      <path
        className="woid__b"
        d="M284.99 621.559a7.123 7.123 0 0111.249 3.014 7.122 7.122 0 007.9 4.563 7.122 7.122 0 018.235 8.234 7.121 7.121 0 004.563 7.9 7.123 7.123 0 013.014 11.249 7.121 7.121 0 000 9.125 7.122 7.122 0 01-3.014 11.248 7.123 7.123 0 00-4.563 7.9 7.123 7.123 0 01-8.235 8.235 7.122 7.122 0 00-7.9 4.562 7.123 7.123 0 01-11.249 3.014 7.123 7.123 0 00-9.125 0 7.123 7.123 0 01-11.249-3.014 7.122 7.122 0 00-7.9-4.562 7.122 7.122 0 01-8.234-8.235 7.124 7.124 0 00-4.563-7.9 7.122 7.122 0 01-3.014-11.248 7.121 7.121 0 000-9.125 7.123 7.123 0 013.014-11.249 7.122 7.122 0 004.563-7.9 7.121 7.121 0 018.234-8.234 7.123 7.123 0 007.9-4.563 7.123 7.123 0 0111.249-3.014 7.123 7.123 0 009.125 0z"
      />
      <text className="woid__d" transform="translate(335.899 588.441)">
        <tspan x={0} y={0}>
          {"CodeLabb"}
        </tspan>
        <tspan x={0} y={32.888}>
          {"Certificate"}
        </tspan>
      </text>
      <path
        className="woid__g"
        d="M719.852 148.546c-.01.258 0 .512-.01.769l39.872 1.452c0-.259-.016-.51-.007-.77.646-17.743 13.974-30.926 33.356-30.22s31.716 14.822 31.07 32.566c-.64 17.58-13.736 30.662-32.828 30.217l-1.491 40.958c40.428 1.193 72.721-29.769 74.175-69.724 1.461-40.129-28.759-73.484-69.435-74.965s-73.242 29.586-74.702 69.717z"
      />
      <path
        className="woid__h"
        d="M744.491 128.329a29.818 29.818 0 1129.817 29.818 29.852 29.852 0 01-29.817-29.818zm9.069 0a20.748 20.748 0 1020.748-20.748 20.772 20.772 0 00-20.748 20.748z"
      />
      <path
        className="woid__h"
        d="M755.383 115.816l6.412-6.412 31.434 31.433-6.413 6.412z"
      />
      <path
        className="woid__f"
        d="M815.084 114.395l-14.615 5.521-4.7 1.775a7.586 7.586 0 00-4.806 5.871l-2.276 13.89a11.377 11.377 0 004.022 10.646l10.891 8.916a7.587 7.587 0 007.487 1.227l4.7-1.775 14.616-5.521 52.878-19.976a7.588 7.588 0 004.417-9.779l-9.956-26.354a7.588 7.588 0 00-9.779-4.417z"
      />
      <text
        transform="rotate(-20.695 816.63 -2137.593)"
        fontSize={29.95}
        fontFamily="JetBrainsMono-Bold,JetBrains Mono"
        fontWeight={700}
        fill="#ef2996"
      >
        <tspan x={0} y={0}>
          {"FREE"}
        </tspan>
      </text>
      <text className="woid__j" transform="translate(729.352 190.19)">
        <tspan x={-49.952} y={0}>
          {"Ad Free"}
        </tspan>
      </text>
      <g>
        <path
          className="woid__g"
          d="M46.602 369.42c0 .258.021.512.026.769l39.9-.361c-.008-.259-.04-.508-.042-.769-.161-17.754 12.554-31.529 31.949-31.7s32.356 13.367 32.516 31.121c.159 17.591-12.329 31.255-31.421 31.677l.371 40.984c40.44-.645 71.293-33.041 70.932-73.022-.364-40.154-32.068-72.1-72.769-71.734s-71.825 32.881-71.462 73.035z"
        />
        <path
          className="woid__b"
          d="M157.018 295.485l-10.292 38.41 35.869-9.611a39.994 39.994 0 00-25.577-28.799z"
        />
        <path
          className="woid__f"
          d="M135.813 344.832l10.338-38.581a39.9 39.9 0 1028.244 28.243z"
        />
        <text className="woid__j" transform="translate(51 407.428)">
          <tspan x={-50.526} y={0}>
            {"Reports"}
          </tspan>
        </text>
      </g>
      <g transform="translate(-652.982 -6288.154)">
        <path
          className="woid__h"
          d="M1066.223 6616.521c0 .258.01.512.009.769l39.895.493c0-.259-.028-.509-.025-.769.219-17.754 13.226-31.254 32.62-31.014s32.063 14.056 31.843 31.81c-.217 17.59-12.995 30.984-32.091 31l-.506 40.983c40.444.221 71.983-31.508 72.477-71.487.5-40.153-30.517-72.772-71.217-73.275s-72.509 31.337-73.005 71.49z"
        />
        <path
          className="woid__k"
          d="M1165.579 6596.516v37.7l44.863-27.387h.008v-37.709z"
        />
        <path
          className="woid__l"
          d="M1151.58 6587.973l-8.627-5.27-22.253-13.583v37.706h.009l44.871 27.387v-37.7z"
        />
        <path
          d="M1185.7 6554.007l-20.12-12.282-21.309 13-23.571 14.395 22.253 13.583 8.627 5.27 14 8.542 44.871-27.4z"
          fill="#78c2f9"
        />
        <ellipse
          className="woid__k"
          cx={9.883}
          cy={4.561}
          rx={9.883}
          ry={4.561}
          transform="translate(1132.506 6564.432)"
        />
        <path
          className="woid__k"
          d="M1132.506 6553.199h19.766v15.794h-19.766z"
        />
        <ellipse
          className="woid__l"
          cx={9.883}
          cy={4.561}
          rx={9.883}
          ry={4.561}
          transform="translate(1132.506 6548.637)"
        />
        <ellipse
          className="woid__k"
          cx={9.883}
          cy={4.561}
          rx={9.883}
          ry={4.561}
          transform="translate(1155.696 6578.401)"
        />
        <path
          className="woid__k"
          d="M1155.696 6567.168h19.766v15.794h-19.766z"
        />
        <ellipse
          className="woid__l"
          cx={9.883}
          cy={4.561}
          rx={9.883}
          ry={4.561}
          transform="translate(1155.696 6562.607)"
        />
        <ellipse
          className="woid__k"
          cx={9.883}
          cy={4.561}
          rx={9.883}
          ry={4.561}
          transform="translate(1155.696 6551.973)"
        />
        <path
          className="woid__k"
          d="M1155.696 6540.74h19.766v15.794h-19.766z"
        />
        <ellipse
          className="woid__l"
          cx={9.883}
          cy={4.561}
          rx={9.883}
          ry={4.561}
          transform="translate(1155.696 6536.179)"
        />
        <ellipse
          className="woid__k"
          cx={9.883}
          cy={4.561}
          rx={9.883}
          ry={4.561}
          transform="translate(1179.071 6564.432)"
        />
        <path
          className="woid__k"
          d="M1179.071 6553.199h19.766v15.794h-19.766z"
        />
        <ellipse
          className="woid__l"
          cx={9.883}
          cy={4.561}
          rx={9.883}
          ry={4.561}
          transform="translate(1179.071 6548.637)"
        />
        <text className="woid__j" transform="translate(1012.953 6652.086)">
          <tspan x={0} y={0}>
            {"Learning"}
          </tspan>
          <tspan x={0} y={32.888}>
            {" is fun!"}
          </tspan>
        </text>
      </g>
      <g>
        <path
          className="woid__a"
          d="M331.903 76.837c.01-.258 0-.512.01-.769l-39.872-1.452c0 .259.016.51.007.77-.646 17.744-13.974 30.926-33.356 30.221s-31.716-14.823-31.07-32.566c.64-17.581 13.736-30.663 32.828-30.217l1.491-40.959C221.513.672 189.22 31.634 187.766 71.589c-1.461 40.13 28.759 73.485 69.435 74.966s73.241-29.589 74.702-69.718z"
        />
        <path
          className="woid__b"
          d="M176.968 104.246l29.367 13.438v-10.977l-16.4-7.353 16.4-7.24V81.123l-29.367 13.5z"
        />
        <path
          className="woid__f"
          d="M211.531 126.351h11.634l21.274-57.3h-11.574z"
        />
        <path
          className="woid__b"
          d="M249.638 81.185v10.981l16.4 7.3-16.4 7.3v10.923l29.367-13.438V94.63z"
        />
        <text className="woid__d" transform="translate(277.882 27)">
          <tspan x={0} y={0}>
            {"A future"}
          </tspan>
          <tspan x={0} y={32.888}>
            {"proof skill"}
          </tspan>
        </text>
      </g>
    </svg>
  );
}

export default WeOfferIconsDesktop;
