import React from "react";
import "./ChangeTheWorldTeaser.scss";
import HelloWorldWindow from "./HelloWorldWindow";

function ChangeTheWorldTeaser() {
  return (
    <section id="ChangeTheWorldTeaser">
      <div className="ctw-container">
        <div className="ctw-title-container">
          <h3>
            Creatively Learn How to Change the
            <br />
            World with Code at
            <strong className="highlight-bg-blue">CodeLabb</strong>
          </h3>
        </div>
        <div className="ctw-teaser">
          <div className="ctw-teaser-content">
            <div className="bg-pattern"></div>
            <div className="svg-container">
              <HelloWorldWindow />
            </div>
            <img src="/media/home/Pam-Progg-686.png" alt="Pam Progg" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default ChangeTheWorldTeaser;
