import React from "react";
import "./KeepInformed.scss";

function KeepInformed() {
  return (
    <section id="KeepInformed">
      <div className="kind-container">
        <div className="kind-title-container">
          <h3>
            <span>
              <strong className="highlight">We'll keep </strong>
              <br />
              you informed
            </span>
          </h3>
        </div>
        <div className="kind-info">
          <p>
            You want to know <strong>well your kids are doing</strong>, what
            courses they are attending or which projects they are working on? No
            problem, we'll send you an <strong>email</strong> whenever your kids
            completes a project or course
          </p>
        </div>
      </div>
    </section>
  );
}

export default KeepInformed;
