import React from "react";
import "./StartFreeTrialTeaser.scss";
import IndividualPlanCard from "./IndividualPlanCard";

function StartFreeTrialTeaser() {
  return (
    <section id="StartFreeTrialTeaser">
      <div className="sftt-container">
        <div className="sftt-individual-plan">
          <IndividualPlanCard />
        </div>
        <div className="sftt-info">
          <div className="sftt-title-container">
            <h3>
              Start Your
              <span className="highlight-1"> FREE</span>
              <span className="highlight-2"> Trial</span>
            </h3>
          </div>
          <div className="sftt-list">
            <ul>
              <li>
                Join now while our service is still{" "}
                <strong className="highlight">FREE</strong>
              </li>
              <li>
                Unlimited access to <strong>all content</strong>
              </li>
              <li>Cancel anytime</li>
              <li>
                <strong>Enjoy! =D</strong>
              </li>
            </ul>
          </div>
          <div className="sftt-buttons">
            <button className="free">Try for FREE</button>
            <button className="gift">Give as Gift</button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default StartFreeTrialTeaser;
