import * as React from "react";

function HelloWorldWindow(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={571.998}
      height={378.764}
      viewBox="0 0 571.998 378.764"
      {...props}
    >
      <defs>
        <clipPath id="hww__a">
          <path fill="none" d="M0 0h210.427v223.368H0z" />
        </clipPath>
        <style>
          {".hww__b{fill:#7d1cc9}.hww__f{fill:#ffea6c}.hww__g{fill:#69ed82}"}
        </style>
      </defs>
      <path
        className="hww__b"
        d="M372.208 37.692c-.61 0-1.21.038-1.818.044v94.349c.611-.014 1.2-.083 1.818-.083 41.986 0 74.286 30.361 74.286 76.227s-32.3 76.224-74.286 76.224c-41.6 0-73.642-29.822-74.232-74.978h-96.92c.659 95.641 76.606 169.289 171.152 169.289 94.957 0 171.181-74.285 171.181-170.535S467.165 37.692 372.208 37.692z"
      />
      <rect
        width={427.863}
        height={292.036}
        rx={42.253}
        transform="translate(0 13.373)"
        fill="#280c4c"
      />
      <g clipPath="url(#hww__a)" transform="translate(361.571)">
        <path
          className="hww__b"
          d="M10.636 37.692c-.61 0-1.21.038-1.818.044v94.349c.611-.014 1.2-.083 1.818-.083 41.986 0 74.286 30.361 74.286 76.227s-32.3 76.224-74.286 76.224c-41.6 0-73.642-29.822-74.232-74.978h-96.92c.659 95.641 76.606 169.289 171.152 169.289 94.957 0 171.181-74.285 171.181-170.535S105.593 37.692 10.636 37.692z"
        />
      </g>
      <circle
        cx={7.414}
        cy={7.414}
        transform="translate(35.086 39.136)"
        fill="#ef2996"
        r={7.414}
      />
      <circle
        className="hww__f"
        cx={7.414}
        cy={7.414}
        transform="translate(60.507 39.136)"
        r={7.414}
      />
      <circle
        className="hww__g"
        cx={7.414}
        cy={7.414}
        transform="translate(85.928 39.136)"
        r={7.414}
      />
      <text
        transform="translate(33.815 130.336)"
        fontSize={37}
        fontFamily="JetBrainsMono-Bold,JetBrains Mono"
        fontWeight={700}
        fill="#ef2996"
      >
        <tspan x={0} y={0}>
          {"Hello"}
        </tspan>
        <tspan className="hww__b" y={0}>
          {", "}
        </tspan>
        <tspan y={0} />
        <tspan className="hww__g" y={0}>
          {"world"}
        </tspan>
        <tspan y={0} fill="#dad5e2">
          {"!"}
        </tspan>
        <tspan className="hww__g">
          <tspan x={0} y={78}>
            {"=D"}
          </tspan>
        </tspan>
      </text>
    </svg>
  );
}

export default HelloWorldWindow;
