import React from "react";
import "./CustomerReview.scss";

function CustomerReview(props: {
  name: string;
  from: string;
  review: string;
  smiley: string;
}) {
  let { name, from, review, smiley } = { ...props };

  return (
    <div className="CustomerReview">
      <div className="cr-container">
        <div className="cr-content">
          <div className="cr-smiley bold-font">{smiley}</div>
          <div className="cr-rating">
            <div className="cr-stars">
              <i className="fa fa-star" aria-hidden="true"></i>
              <i className="fa fa-star" aria-hidden="true"></i>
              <i className="fa fa-star" aria-hidden="true"></i>
              <i className="fa fa-star" aria-hidden="true"></i>
              <i className="fa fa-star" aria-hidden="true"></i>
            </div>
            <div className="cr-who">
              <p className="bold-font">{name}</p>
              <span>{from}</span>
            </div>
          </div>
          <p>{review}</p>
        </div>
      </div>
    </div>
  );
}

export default CustomerReview;
