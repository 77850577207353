import * as React from "react";

function IndividualPlanCard(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={270.811}
      height={370.048}
      viewBox="0 0 270.811 370.048"
      {...props}
    >
      <defs>
        <clipPath id="sftt__a">
          <path fill="none" d="M0 0h260.352v85.694H0z" />
        </clipPath>
        <style>
          {
            ".sftt__b{fill:#69ed82}.sftt__d{fill:#7d1cc9}.sftt__e{fill:#ffea6c}.sftt__f{fill:#ef2996}.sftt__g{fill:#410e7c}"
          }
        </style>
      </defs>
      <path
        className="sftt__b"
        d="M235.671 0H33.994A33.992 33.992 0 00.002 33.992v302.064a33.992 33.992 0 0033.992 33.992h201.677a33.992 33.992 0 0033.992-33.992V33.992A33.992 33.992 0 00235.671 0zm-67.049 33.462H99.057c-4.384-3.7-1.736-8.529 1.985-8.529h69.564c4.388 3.699 1.736 8.529-1.985 8.529z"
      />
      <g clipPath="url(#sftt__a)" transform="translate(10.46 270.018)">
        <g transform="translate(18.785 22.997)">
          <path
            className="sftt__b"
            d="M24.13 25.111a10.993 10.993 0 1110.993 10.951A10.872 10.872 0 0124.13 25.111zm15.763 0a4.772 4.772 0 10-4.77 4.9 4.652 4.652 0 004.77-4.9z"
          />
          <path
            className="sftt__d"
            d="M39.894 25.11a4.772 4.772 0 10-4.77 4.895 4.652 4.652 0 004.77-4.895z"
          />
          <path
            className="sftt__e"
            d="M117.559 95.183a10.993 10.993 0 1110.993 10.951 10.872 10.872 0 01-10.993-10.951zm15.763 0a4.772 4.772 0 10-4.771 4.895 4.653 4.653 0 004.772-4.895z"
          />
          <path
            className="sftt__f"
            d="M133.323 95.181a4.772 4.772 0 10-4.771 4.9 4.652 4.652 0 004.771-4.9z"
          />
          <ellipse
            className="sftt__f"
            cx={4.936}
            cy={5.061}
            rx={4.936}
            ry={5.061}
            transform="translate(103.503 34.431)"
          />
          <ellipse
            className="sftt__g"
            cx={4.936}
            cy={5.061}
            rx={4.936}
            ry={5.061}
            transform="translate(84.37 76.807)"
          />
          <ellipse
            className="sftt__d"
            cx={4.936}
            cy={5.061}
            rx={4.936}
            ry={5.061}
            transform="translate(7.414 44.682)"
          />
          <path
            className="sftt__e"
            d="M64.542 51.636a4.767 4.767 0 11-9.534 0h-6.224a10.992 10.992 0 0021.982 0z"
          />
          <path
            className="sftt__g"
            d="M85.991 0h-.117v6.06c.039 0 .077-.006.117-.006a4.9 4.9 0 11-4.768 4.976h-6.224A10.992 10.992 0 1085.99-.001zm-50.91 91.301v-.117h-6.059c0 .039.006.077.006.117a4.9 4.9 0 11-4.975-4.769v-6.223A10.992 10.992 0 1035.084 91.3z"
          />
          <path
            className="sftt__e"
            d="M8.606 6.101V1.466H3.761L.001 4.32v1.781h8.605z"
          />
          <path
            className="sftt__d"
            d="M138.377 24.396v-4.633h-4.845l-3.76 2.853v1.78h8.605z"
          />
          <path className="sftt__b" d="M133.508 24.397h4.865v13.97h-4.865z" />
          <path
            className="sftt__f"
            d="M3.74 9.488h4.865v7.302H3.74zm57.19 97.399v-4.638h-4.845l-3.76 2.853v1.781h8.605z"
          />
          <path fill="#f5feff" d="M56.064 106.887h4.865v6.018h-4.865z" />
          <path
            className="sftt__f"
            d="M186.928 25.111a10.993 10.993 0 1110.994 10.951 10.872 10.872 0 01-10.994-10.951zm15.763 0a4.772 4.772 0 10-4.77 4.9 4.652 4.652 0 004.77-4.9z"
          />
          <path
            className="sftt__e"
            d="M202.691 25.11a4.772 4.772 0 10-4.77 4.895 4.652 4.652 0 004.77-4.895z"
          />
          <ellipse
            className="sftt__e"
            cx={4.936}
            cy={5.061}
            rx={4.936}
            ry={5.061}
            transform="translate(167.1 51.425)"
          />
          <path
            className="sftt__f"
            d="M197.879 91.301v-.117h-6.059c0 .039.005.077.005.117a4.9 4.9 0 11-4.975-4.769v-6.223A10.992 10.992 0 10197.881 91.3z"
          />
          <path
            className="sftt__e"
            d="M171.403 6.101V1.466h-4.845l-3.76 2.854v1.781h8.605z"
          />
          <path className="sftt__g" d="M166.538 9.488h4.865v7.302h-4.865z" />
        </g>
      </g>
      <text
        transform="translate(131.539 83.967)"
        fontFamily="Gilroy-ExtraBold,Gilroy"
        fontWeight={800}
        fontSize={32}
        fill="#7d1cc9"
      >
        <tspan x={-74.16} y={0}>
          {"Individual"}
        </tspan>
      </text>
      <g transform="translate(113.498 202.755)">
        <path className="sftt__f" d="M10.73 4.289h3.219v21.46H10.73z" />
        <circle
          className="sftt__f"
          cx={4.291}
          cy={4.291}
          r={4.291}
          transform="translate(8.049)"
        />
        <path
          className="sftt__f"
          d="M12.8 15.02h17.323a12.8 12.8 0 0112.8 12.8v21.641A17.064 17.064 0 0125.857 66.52h-8.793A17.064 17.064 0 010 49.461V27.82a12.8 12.8 0 0112.8-12.8z"
        />
        <rect
          className="sftt__d"
          width={42.92}
          height={21.46}
          rx={10.73}
          transform="translate(0 15.02)"
        />
        <path
          className="sftt__e"
          d="M8.933 47.21h25.054a1.422 1.422 0 011.424 1.422v1.278a9.1 9.1 0 01-9.1 9.1h-9.7a9.1 9.1 0 01-9.1-9.1v-1.278a1.422 1.422 0 011.422-1.422z"
        />
        <g transform="translate(9.657 23.603)">
          <path
            className="sftt__b"
            d="M3.756 18.241a2.683 2.683 0 01-2.683-2.683V-.001h5.366v15.559a2.683 2.683 0 01-2.683 2.683z"
          />
          <rect className="sftt__b" width={7.511} height={2.146} rx={1.073} />
        </g>
        <g transform="translate(25.752 23.603)">
          <path
            className="sftt__b"
            d="M3.756 18.241a2.683 2.683 0 01-2.683-2.683V-.001h5.368v15.559a2.683 2.683 0 01-2.685 2.683z"
          />
          <rect className="sftt__b" width={7.511} height={2.146} rx={1.073} />
        </g>
      </g>
      <text
        transform="translate(134.809 128.14)"
        fontSize={22}
        fontFamily="Gilroy-ExtraBold,Gilroy"
        fontWeight={800}
        fill="#7d1cc9"
      >
        <tspan x={-53.361} y={0}>
          {"CodeLabb"}
        </tspan>
        <tspan x={-66.55} y={26}>
          {"Monthly Plan"}
        </tspan>
        <tspan className="sftt__f">
          <tspan x={-47.003} y={52}>
            {"Free Trial"}
          </tspan>
        </tspan>
      </text>
    </svg>
  );
}

export default IndividualPlanCard;
