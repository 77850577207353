import React from "react";
import "./TopTeaser.scss";

function TopTeaser() {
  return (
    <div id="TopTeaser">
      <div className="tt-container">
        <h1>Coding Fun for Kids</h1>
        <h2>Get in the Labb!</h2>
        <p>
          Learn how to change the world with code using our great library of
          video tutorials, built in online editor and wonderful kid community.
        </p>
        <div className="tt-buttons">
          <button className="btn-free">Try for FREE</button>
          <button className="btn-gift">Give as Gift</button>
        </div>
      </div>
    </div>
  );
}

export default TopTeaser;
