import * as React from "react";

function SkillCoursesDesktop() {
  return (
    <svg width={2430.729} height={1746.976} viewBox="0 0 2430.729 1746.976">
      <defs>
        <style>
          {
            ".skcd__a,.skcd__f{fill:#410e7c}.skcd__b{fill:#7cdd8a}.skcd__c{fill:#ef2996}.skcd__d,.skcd__i{fill:#ffea6c}.skcd__e{fill:#99e7ff}.skcd__f,.skcd__i{font-size:50px;font-family:JetBrainsMono-Bold,JetBrains Mono;font-weight:700}.skcd__j{fill:#78c2f9}"
          }
        </style>
      </defs>
      <g transform="translate(282.03 -2820.78)">
        <rect
          className="skcd__a"
          width={457.396}
          height={307.981}
          rx={51.632}
          transform="translate(669.851 3473.103)"
        />
        <rect
          className="skcd__b"
          width={416.571}
          height={239.928}
          rx={40.697}
          transform="translate(689.894 3522.646)"
        />
        <circle
          className="skcd__c"
          cx={8.695}
          cy={8.695}
          r={8.695}
          transform="translate(713.783 3491.283)"
        />
        <circle
          className="skcd__d"
          cx={8.695}
          cy={8.695}
          r={8.695}
          transform="translate(743.598 3491.283)"
        />
        <circle
          className="skcd__e"
          cx={8.695}
          cy={8.695}
          r={8.695}
          transform="translate(773.413 3491.283)"
        />
        <text className="skcd__f" transform="translate(713.292 3600.184)">
          <tspan x={0} y={0}>
            {"/Java"}
          </tspan>
          <tspan x={0} y={58.19}>
            {" Script"}
          </tspan>
        </text>
        <rect
          className="skcd__a"
          width={457.396}
          height={307.981}
          rx={51.632}
          transform="translate(1134.58 2820.78)"
        />
        <rect
          className="skcd__c"
          width={416.571}
          height={239.928}
          rx={40.697}
          transform="translate(1154.624 2870.323)"
        />
        <circle
          className="skcd__c"
          cx={8.695}
          cy={8.695}
          r={8.695}
          transform="translate(1178.513 2838.96)"
        />
        <circle
          className="skcd__d"
          cx={8.695}
          cy={8.695}
          r={8.695}
          transform="translate(1208.328 2838.96)"
        />
        <circle
          className="skcd__e"
          cx={8.695}
          cy={8.695}
          r={8.695}
          transform="translate(1238.143 2838.96)"
        />
        <text
          transform="translate(1177.022 2939.972)"
          fontSize={50}
          fontFamily="JetBrainsMono-Bold,JetBrains Mono"
          fontWeight={700}
          fill="#7cdd8a"
        >
          <tspan x={0} y={0}>
            {"/HTML"}
          </tspan>
        </text>
        <rect
          className="skcd__a"
          width={457.396}
          height={307.981}
          rx={51.632}
          transform="translate(1691.302 3654.668)"
        />
        <rect
          className="skcd__b"
          width={416.571}
          height={239.928}
          rx={40.697}
          transform="translate(1711.345 3704.211)"
        />
        <circle
          className="skcd__c"
          cx={8.695}
          cy={8.695}
          r={8.695}
          transform="translate(1735.234 3672.849)"
        />
        <circle
          className="skcd__d"
          cx={8.695}
          cy={8.695}
          r={8.695}
          transform="translate(1765.049 3672.849)"
        />
        <circle
          className="skcd__e"
          cx={8.695}
          cy={8.695}
          r={8.695}
          transform="translate(1794.864 3672.849)"
        />
        <text className="skcd__f" transform="translate(1733.743 3779.227)">
          <tspan x={0} y={0}>
            {"/HTML"}
          </tspan>
        </text>
        <rect
          className="skcd__a"
          width={400.561}
          height={267.81}
          rx={45.057}
          transform="translate(1270.836 3280.964)"
        />
        <rect
          className="skcd__d"
          width={364.809}
          height={201.859}
          rx={34.933}
          transform="translate(1288.389 3330.82)"
        />
        <circle
          className="skcd__c"
          cx={8.695}
          cy={8.695}
          r={8.695}
          transform="translate(1314.768 3299.145)"
        />
        <circle
          className="skcd__d"
          cx={8.695}
          cy={8.695}
          r={8.695}
          transform="translate(1344.583 3299.145)"
        />
        <circle
          className="skcd__e"
          cx={8.695}
          cy={8.695}
          r={8.695}
          transform="translate(1374.398 3299.145)"
        />
        <text
          transform="translate(1313.277 3402.84)"
          fill="#e5309c"
          fontSize={50}
          fontFamily="JetBrainsMono-Bold,JetBrains Mono"
          fontWeight={700}
        >
          <tspan x={0} y={0}>
            {"/CSS"}
          </tspan>
        </text>
        <rect
          className="skcd__a"
          width={400.561}
          height={267.81}
          rx={45.057}
          transform="translate(1114.488 3855.743)"
        />
        <rect
          className="skcd__c"
          width={364.809}
          height={201.859}
          rx={34.933}
          transform="translate(1132.041 3905.598)"
        />
        <circle
          className="skcd__c"
          cx={8.695}
          cy={8.695}
          r={8.695}
          transform="translate(1158.42 3873.923)"
        />
        <circle
          className="skcd__d"
          cx={8.695}
          cy={8.695}
          r={8.695}
          transform="translate(1188.235 3873.923)"
        />
        <circle
          className="skcd__e"
          cx={8.695}
          cy={8.695}
          r={8.695}
          transform="translate(1218.05 3873.923)"
        />
        <text className="skcd__i" transform="translate(1156.929 3978.513)">
          <tspan x={0} y={0}>
            {"/Python"}
          </tspan>
        </text>
        <rect
          className="skcd__a"
          width={400.561}
          height={267.81}
          rx={45.057}
          transform="translate(-187.514 3730.525)"
        />
        <rect
          className="skcd__c"
          width={364.809}
          height={201.859}
          rx={34.933}
          transform="translate(-169.961 3780.381)"
        />
        <circle
          className="skcd__c"
          cx={8.695}
          cy={8.695}
          r={8.695}
          transform="translate(-143.581 3748.706)"
        />
        <circle
          className="skcd__d"
          cx={8.695}
          cy={8.695}
          r={8.695}
          transform="translate(-113.766 3748.706)"
        />
        <circle
          className="skcd__e"
          cx={8.695}
          cy={8.695}
          r={8.695}
          transform="translate(-83.951 3748.706)"
        />
        <text className="skcd__i" transform="translate(-145.072 3849.718)">
          <tspan x={0} y={0}>
            {"/CSS"}
          </tspan>
        </text>
        <rect
          className="skcd__a"
          width={400.561}
          height={267.81}
          rx={45.057}
          transform="translate(-282.03 3113.026)"
        />
        <rect
          className="skcd__c"
          width={364.809}
          height={201.859}
          rx={34.933}
          transform="translate(-264.477 3162.881)"
        />
        <circle
          className="skcd__c"
          cx={8.695}
          cy={8.695}
          r={8.695}
          transform="translate(-238.098 3131.206)"
        />
        <circle
          className="skcd__d"
          cx={8.695}
          cy={8.695}
          r={8.695}
          transform="translate(-208.283 3131.206)"
        />
        <circle
          className="skcd__e"
          cx={8.695}
          cy={8.695}
          r={8.695}
          transform="translate(-178.468 3131.206)"
        />
        <text className="skcd__i" transform="translate(-239.589 3225.927)">
          <tspan x={0} y={0}>
            {"/CSS"}
          </tspan>
        </text>
        <rect
          className="skcd__a"
          width={400.561}
          height={267.81}
          rx={45.057}
          transform="translate(210.222 3374.769)"
        />
        <rect
          className="skcd__d"
          width={364.809}
          height={201.859}
          rx={34.933}
          transform="translate(227.775 3424.625)"
        />
        <circle
          className="skcd__c"
          cx={8.695}
          cy={8.695}
          r={8.695}
          transform="translate(254.155 3392.95)"
        />
        <circle
          className="skcd__d"
          cx={8.695}
          cy={8.695}
          r={8.695}
          transform="translate(283.97 3392.95)"
        />
        <circle
          className="skcd__e"
          cx={8.695}
          cy={8.695}
          r={8.695}
          transform="translate(313.785 3392.95)"
        />
        <text className="skcd__f" transform="translate(252.664 3494.65)">
          <tspan x={0} y={0}>
            {"/NodeJS"}
          </tspan>
        </text>
        <rect
          className="skcd__a"
          width={400.561}
          height={267.81}
          rx={45.057}
          transform="translate(-161.574 4299.946)"
        />
        <rect
          className="skcd__b"
          width={364.809}
          height={201.859}
          rx={34.933}
          transform="translate(-144.021 4349.802)"
        />
        <circle
          className="skcd__c"
          cx={8.695}
          cy={8.695}
          r={8.695}
          transform="translate(-117.642 4318.127)"
        />
        <circle
          className="skcd__d"
          cx={8.695}
          cy={8.695}
          r={8.695}
          transform="translate(-87.827 4318.127)"
        />
        <circle
          className="skcd__e"
          cx={8.695}
          cy={8.695}
          r={8.695}
          transform="translate(-58.012 4318.127)"
        />
        <text className="skcd__f" transform="translate(-119.133 4420.033)">
          <tspan x={0} y={0}>
            {"/CSS"}
          </tspan>
        </text>
        <rect
          className="skcd__a"
          width={368.767}
          height={242.59}
          rx={41.146}
          transform="translate(705.905 3010.839)"
        />
        <rect
          className="skcd__j"
          width={335.853}
          height={176.824}
          rx={31.371}
          transform="translate(722.065 3062.025)"
        />
        <circle
          className="skcd__c"
          cx={8.695}
          cy={8.695}
          r={8.695}
          transform="translate(749.838 3029.019)"
        />
        <circle
          className="skcd__d"
          cx={8.695}
          cy={8.695}
          r={8.695}
          transform="translate(779.653 3029.019)"
        />
        <circle
          className="skcd__e"
          cx={8.695}
          cy={8.695}
          r={8.695}
          transform="translate(809.468 3029.019)"
        />
        <text className="skcd__f" transform="translate(748.347 3132.92)">
          <tspan x={0} y={0}>
            {"/SQL"}
          </tspan>
        </text>
        <rect
          className="skcd__a"
          width={368.767}
          height={242.59}
          rx={41.146}
          transform="translate(379.58 3912.725)"
        />
        <rect
          className="skcd__j"
          width={335.853}
          height={176.824}
          rx={31.371}
          transform="translate(395.74 3963.911)"
        />
        <circle
          className="skcd__c"
          cx={8.695}
          cy={8.695}
          r={8.695}
          transform="translate(423.512 3930.906)"
        />
        <circle
          className="skcd__d"
          cx={8.695}
          cy={8.695}
          r={8.695}
          transform="translate(453.327 3930.906)"
        />
        <circle
          className="skcd__e"
          cx={8.695}
          cy={8.695}
          r={8.695}
          transform="translate(483.142 3930.906)"
        />
        <text className="skcd__f" transform="translate(422.021 4034.02)">
          <tspan x={0} y={0}>
            {"/Swift"}
          </tspan>
        </text>
        <rect
          className="skcd__a"
          width={368.767}
          height={242.59}
          rx={41.146}
          transform="translate(1691.302 2944.879)"
        />
        <rect
          className="skcd__j"
          width={335.853}
          height={176.824}
          rx={31.371}
          transform="translate(1707.462 2996.065)"
        />
        <circle
          className="skcd__c"
          cx={8.695}
          cy={8.695}
          r={8.695}
          transform="translate(1735.234 2963.059)"
        />
        <circle
          className="skcd__d"
          cx={8.695}
          cy={8.695}
          r={8.695}
          transform="translate(1765.049 2963.059)"
        />
        <circle
          className="skcd__e"
          cx={8.695}
          cy={8.695}
          r={8.695}
          transform="translate(1794.864 2963.059)"
        />
        <text className="skcd__f" transform="translate(1733.743 3066.174)">
          <tspan x={0} y={0}>
            {"/Java"}
          </tspan>
        </text>
      </g>
    </svg>
  );
}

export default SkillCoursesDesktop;
