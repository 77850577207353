import * as React from "react";

function WeOfferIconsTablet() {
  return (
    <svg width={712.407} height={1082.777} viewBox="0 0 712.407 1082.777">
      <defs>
        <style>
          {
            ".woit__a{fill:#7d1cc9}.woit__b,.woit__k{fill:#ffea6c}.woit__f,.woit__g{fill:#7cdd8a}.woit__g,.woit__k{font-family:Gilroy-ExtraBold,Gilroy;font-weight:800;font-size:28px}.woit__h{fill:#960863}.woit__i{fill:#ef2996}.woit__m{fill:#6498ed}.woit__n{fill:#99e7ff}"
          }
        </style>
      </defs>
      <path
        className="woit__a"
        d="M573.691 1082.765c.282.012.561 0 .842.012l1.59-43.652c-.284 0-.558.018-.843.008-19.425-.707-33.858-15.3-33.085-36.518s16.227-34.722 35.652-34.014c19.248.7 33.569 15.039 33.082 35.938l44.841 1.634c1.306-44.26-32.591-79.613-76.334-81.207-43.932-1.6-80.448 31.486-82.07 76.017s32.393 80.184 76.325 81.782z"
      />
      <path
        className="woit__b"
        d="M556.681 1018.155l-2.445-1.271c-30.791-16.013-44.815-40.168-44.815-55.834v-46.8h5.3c19.854 0 29.916-2.676 38.45-10.228l3.512-3.106 3.512 3.106c8.534 7.551 18.6 10.228 38.448 10.228h5.3v46.8c0 15.666-14.024 39.821-44.815 55.834zm-36.661-93.373v36.267c0 10.185 9.819 30.3 36.661 45.119 26.84-14.819 36.659-34.934 36.659-45.119v-36.267c-16.961-.475-27.674-3.373-36.659-9.977-8.987 6.605-19.698 9.503-36.66 9.978z"
      />
      <path
        d="M578.137 949.932l-18.049 18.048a13.073 13.073 0 01-18.472 0l-6.391-6.392 7.567-7.566 6.393 6.392a2.362 2.362 0 003.337 0l18.049-18.049z"
        fill="#69ed82"
      />
      <text
        transform="translate(587.407 1037.847)"
        fontFamily="Gilroy-ExtraBold,Gilroy"
        fontWeight={800}
        fontSize={27}
        fill="#7cdd8a"
      >
        <tspan x={0} y={0}>
          {"Adult and"}
        </tspan>
        <tspan x={0} y={32}>
          {"Bully Free"}
        </tspan>
      </text>
      <path
        d="M533.646 676.541c0-.281-.019-.561-.022-.842l-43.68.064c.007.282.039.556.039.84.028 19.438-14.006 34.413-35.24 34.443s-35.309-14.9-35.337-34.34c-.028-19.26 13.758-34.115 34.662-34.417l-.064-44.871c-44.279.37-78.325 35.577-78.261 79.349.063 43.961 34.506 79.2 79.066 79.138s78.901-35.398 78.837-79.364z"
        fill="#d81e8d"
      />
      <path
        className="woit__f"
        d="M450.908 770.65l-13.864-6.087-6.579 13.638-23.7-64.165 20.443-7.55z"
      />
      <path
        className="woit__f"
        d="M373.899 770.65l13.864-6.087 6.579 13.638 23.7-64.165-20.443-7.55z"
      />
      <path
        className="woit__b"
        d="M417.403 655.539a7.8 7.8 0 0112.314 3.3 7.8 7.8 0 008.652 4.995 7.8 7.8 0 019.015 9.015 7.794 7.794 0 005 8.65 7.8 7.8 0 013.3 12.316 7.8 7.8 0 000 9.99 7.8 7.8 0 01-3.3 12.315 7.8 7.8 0 00-5 8.652 7.8 7.8 0 01-9.015 9.015 7.8 7.8 0 00-8.652 5 7.8 7.8 0 01-12.314 3.3 7.8 7.8 0 00-9.99 0 7.8 7.8 0 01-12.314-3.3 7.8 7.8 0 00-8.652-5 7.8 7.8 0 01-9.015-9.015 7.8 7.8 0 00-5-8.652 7.8 7.8 0 01-3.3-12.315 7.8 7.8 0 000-9.99 7.8 7.8 0 013.3-12.316 7.8 7.8 0 005-8.65 7.8 7.8 0 019.015-9.015 7.8 7.8 0 008.652-4.995 7.8 7.8 0 0112.314-3.3 7.8 7.8 0 009.99 0z"
      />
      <text className="woit__g" transform="translate(473.132 616.694)">
        <tspan x={0} y={0}>
          {"CodeLabb"}
        </tspan>
        <tspan x={0} y={33}>
          {"Certificate"}
        </tspan>
      </text>
      <path
        className="woit__h"
        d="M484.416 390.782c-.009.282 0 .56-.009.841l43.65 1.589c0-.283-.017-.559-.008-.842.707-19.426 15.3-33.858 36.518-33.086s34.721 16.228 34.014 35.653c-.7 19.246-15.037 33.568-35.938 33.081l-1.633 44.841c44.261 1.306 79.613-32.591 81.207-76.333 1.6-43.933-31.487-80.45-76.017-82.071s-80.185 32.394-81.784 76.327z"
      />
      <path
        className="woit__i"
        d="M511.39 368.651a32.683 32.683 0 0132.645-32.645 32.682 32.682 0 0132.645 32.645 32.681 32.681 0 01-32.645 32.644 32.682 32.682 0 01-32.645-32.644zm9.93 0a22.74 22.74 0 0022.715 22.709 22.741 22.741 0 0022.715-22.714 22.741 22.741 0 00-22.715-22.714 22.74 22.74 0 00-22.715 22.719z"
      />
      <path
        className="woit__i"
        d="M523.316 354.951l7.02-7.02 34.413 34.412-7.02 7.02z"
      />
      <path
        className="woit__f"
        d="M588.675 353.395l-16 6.045-5.143 1.943a8.307 8.307 0 00-5.262 6.427l-2.492 15.208a12.459 12.459 0 004.4 11.655l11.923 9.76a8.307 8.307 0 008.2 1.344l5.144-1.943 16-6.045 57.891-21.869a8.307 8.307 0 004.835-10.706l-10.9-28.853a8.307 8.307 0 00-10.706-4.835z"
      />
      <text
        transform="rotate(-20.695 1355.38 -1392.545)"
        fontSize={29}
        fontFamily="JetBrainsMono-Bold,JetBrains Mono"
        fontWeight={700}
        fill="#ef2996"
      >
        <tspan x={0} y={0}>
          {"FREE"}
        </tspan>
      </text>
      <text className="woit__k" transform="translate(489.673 433.785)">
        <tspan x={-49.952} y={0}>
          {"Ad Free"}
        </tspan>
      </text>
      <g>
        <path
          className="woit__h"
          d="M349.888 174.805c0 .281.023.559.028.84l43.677-.4c-.008-.283-.043-.556-.046-.841-.175-19.438 13.745-34.519 34.978-34.709s35.422 14.634 35.6 34.07c.175 19.258-13.5 34.216-34.4 34.68l.406 44.868c44.273-.706 78.052-36.174 77.655-79.942-.4-43.961-35.107-78.937-79.665-78.534s-78.631 36.005-78.233 79.968z"
        />
        <path
          className="woit__b"
          d="M470.767 93.86L459.5 135.91l39.268-10.521a43.787 43.787 0 00-28.001-31.529z"
        />
        <path
          className="woit__f"
          d="M447.554 147.884l11.318-42.238a43.728 43.728 0 00-11.318-1.482 43.721 43.721 0 00-43.72 43.72 43.721 43.721 0 0043.72 43.722 43.721 43.721 0 0043.722-43.722 43.745 43.745 0 00-1.483-11.318z"
        />
        <text
          transform="translate(347.213 212.824)"
          fontFamily="Gilroy-ExtraBold,Gilroy"
          fontWeight={800}
          fontSize={27}
          fill="#ffea6c"
        >
          <tspan x={-48.722} y={0}>
            {"Reports"}
          </tspan>
        </text>
      </g>
      <g>
        <path
          className="woit__i"
          d="M109.068 446.58c0 .283.011.559.011.842l43.676.539c0-.284-.031-.557-.028-.842.241-19.437 14.48-34.216 35.713-33.953s35.1 15.386 34.861 34.824c-.238 19.258-14.227 33.92-35.133 33.936l-.554 44.867c44.278.242 78.806-34.495 79.347-78.263.544-43.958-33.41-79.67-77.967-80.22s-79.38 34.311-79.926 78.27z"
        />
        <g transform="translate(168.71 358.622)">
          <path
            className="woit__m"
            d="M49.134 66.057v41.27l49.115-29.982h.009V36.064z"
          />
          <path
            className="woit__n"
            d="M33.807 56.704l-9.445-5.77L0 36.063v41.281h.01l49.124 29.982v-41.27z"
          />
          <path
            d="M71.16 19.519L49.133 6.072 25.804 20.31.004 36.063l24.362 14.871 9.445 5.77 15.327 9.353 49.125-29.993z"
            fill="#78c2f9"
          />
          <g transform="translate(12.926 18.634)">
            <ellipse
              className="woit__m"
              cx={10.82}
              cy={4.994}
              rx={10.82}
              ry={4.994}
              transform="translate(0 12.297)"
            />
            <path className="woit__m" d="M0 0h21.64v17.291H0z" />
          </g>
          <ellipse
            className="woit__n"
            cx={10.82}
            cy={4.994}
            rx={10.82}
            ry={4.994}
            transform="translate(12.926 13.639)"
          />
          <g transform="translate(38.314 33.927)">
            <ellipse
              className="woit__m"
              cx={10.82}
              cy={4.994}
              rx={10.82}
              ry={4.994}
              transform="translate(0 12.298)"
            />
            <path className="woit__m" d="M0 0h21.64v17.291H0z" />
          </g>
          <ellipse
            className="woit__n"
            cx={10.82}
            cy={4.994}
            rx={10.82}
            ry={4.994}
            transform="translate(38.314 28.933)"
          />
          <g transform="translate(38.314 4.994)">
            <ellipse
              className="woit__m"
              cx={10.82}
              cy={4.994}
              rx={10.82}
              ry={4.994}
              transform="translate(0 12.298)"
            />
            <path className="woit__m" d="M0 0h21.64v17.291H0z" />
          </g>
          <ellipse
            className="woit__n"
            cx={10.82}
            cy={4.994}
            rx={10.82}
            ry={4.994}
            transform="translate(38.314)"
          />
          <g transform="translate(63.904 18.634)">
            <ellipse
              className="woit__m"
              cx={10.82}
              cy={4.994}
              rx={10.82}
              ry={4.994}
              transform="translate(0 12.297)"
            />
            <path className="woit__m" d="M0 0h21.64v17.291H0z" />
          </g>
          <ellipse
            className="woit__n"
            cx={10.82}
            cy={4.994}
            rx={10.82}
            ry={4.994}
            transform="translate(63.904 13.639)"
          />
        </g>
        <text className="woit__k" transform="translate(50.75 482.927)">
          <tspan x={0} y={0}>
            {"Learning"}
          </tspan>
          <tspan x={0} y={33}>
            {" is fun!"}
          </tspan>
        </text>
      </g>
      <g>
        <path
          className="woit__a"
          d="M169.621 84.15c.011-.283 0-.562.011-.842l-43.65-1.589c-.006.284.017.558.006.843-.707 19.425-15.3 33.858-36.518 33.085S54.749 99.419 55.456 79.994c.7-19.247 15.038-33.567 35.938-33.081l1.633-44.842C48.767.765 13.414 34.662 11.821 78.404c-1.6 43.933 31.485 80.451 76.017 82.072s80.184-32.393 81.783-76.326z"
        />
        <path
          className="woit__b"
          d="M0 114.155l32.15 14.711v-12.017L14.193 108.8l17.957-7.926V88.841L0 103.624z"
        />
        <path
          className="woit__f"
          d="M37.839 138.357h12.736l23.291-62.729h-12.67z"
        />
        <path
          className="woit__b"
          d="M79.558 88.91v12.021l17.956 7.988-17.956 7.988v11.959l32.15-14.711v-10.533z"
        />
        <text className="woit__g" transform="translate(110.479 27)">
          <tspan x={0} y={0}>
            {"A future"}
          </tspan>
          <tspan x={0} y={33}>
            {"proof skill"}
          </tspan>
        </text>
      </g>
    </svg>
  );
}

export default WeOfferIconsTablet;
