import React from "react";
import "./CodingCoursesBullets.scss";

function CodingCoursesBullets() {
  return (
    <section id="CodingCoursesBullets">
      <div className="ccb-container">
        <div className="ccb-title-container">
          <h3>
            <span className="highlight"> Coding Courses for Kids at the </span>
            <span className="highlight-bg-yellow">
              <i className="body-text">Age of 8+:</i>
            </span>
          </h3>
        </div>
        <div className="ccb-bullets">
          <div className="ccb-bullets-1">
            <ul>
              <li>
                <span>
                  All videos are <strong> ad-free </strong>
                  <br />.
                </span>
              </li>
              <li>
                <span>
                  All beginners courses require
                  <strong>
                    {" "}
                    no previous
                    <br />
                    knowledge
                  </strong>{" "}
                  whatsoever.
                </span>
              </li>
              <li>
                <span>
                  <strong> Each Lesson </strong> in a course
                  <br />
                  <strong> continues </strong> where the previous one left off.
                </span>
              </li>
              <li>
                <span>
                  Kids can <strong> ask questions and get help </strong> from
                  <br />
                  real professional programmers!
                </span>
              </li>
            </ul>
          </div>
          <div className="ccb-bullets-2">
            <ul>
              <li>
                <span>
                  Our lessons are designed with
                  <strong> zero frustation </strong>
                  <br />
                  in mind due to our short and easy to follow lessons.
                </span>
              </li>
              <li>
                <span>
                  <strong> Kids choose </strong> which programming
                  <br />
                  languages they like the best.
                </span>
              </li>
              <li>
                <span>
                  From the <strong>age of 10</strong> kids can
                  <strong>
                    {" "}
                    teach other
                    <br />
                    kids{" "}
                  </strong>{" "}
                  or create their own fun challenges.
                </span>
              </li>
              <li>
                <span>
                  Kids learn <strong> preassure free </strong> because the
                  easiest way
                  <br />
                  to learn a new programming language is to
                  <strong> enjoy it </strong>!
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CodingCoursesBullets;
