import React from "react";
import "./MeetOurTeamMember.scss";

function MeetOurTeamMember(props: {
  name: string;
  color: string;
  intro: string;
}) {
  let { name, color, intro } = { ...props };
  let encodedName = name.replace(" ", "-").toLocaleLowerCase();

  return (
    <div className={`MeetOurTeamMember motm-${encodedName}`}>
      <div className="motm-container">
        <h4 className="bold-font">{name}</h4>
        <div className={`motm-member motm-c-${color}`}>
          <img src={`/media/common/space.png`} alt={name} />
        </div>
        <p>{intro}</p>
      </div>
    </div>
  );
}

export default MeetOurTeamMember;
