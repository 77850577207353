import * as React from "react";

function SkillCoursesTablet() {
  return (
    <svg width={1910.816} height={1373.313} viewBox="0 0 1910.816 1373.313">
      <defs>
        <style>
          {
            ".skct__a,.skct__f,.skct__h{fill:#410e7c}.skct__b{fill:#7cdd8a}.skct__c{fill:#ef2996}.skct__d,.skct__k{fill:#ffea6c}.skct__e{fill:#99e7ff}.skct__f,.skct__h,.skct__k{font-size:37px;font-family:JetBrainsMono-Bold,JetBrains Mono;font-weight:700}.skct__h,.skct__k{font-size:38px}.skct__l{fill:#78c2f9}"
          }
        </style>
      </defs>
      <g transform="translate(748.281 512.796)">
        <rect
          className="skct__a"
          width={359.563}
          height={242.106}
          rx={51.632}
        />
        <rect
          className="skct__b"
          width={327.47}
          height={188.61}
          rx={40.697}
          transform="translate(15.756 38.946)"
        />
        <circle
          className="skct__c"
          cx={6.835}
          cy={6.835}
          r={6.835}
          transform="translate(34.536 14.292)"
        />
        <circle
          className="skct__d"
          cx={6.835}
          cy={6.835}
          r={6.835}
          transform="translate(57.974 14.292)"
        />
        <circle
          className="skct__e"
          cx={6.835}
          cy={6.835}
          r={6.835}
          transform="translate(81.411 14.292)"
        />
        <text className="skct__f" transform="translate(34.15 97.38)">
          <tspan x={0} y={0}>
            {"/Java"}
          </tspan>
          <tspan x={0} y={43}>
            {" Script"}
          </tspan>
        </text>
      </g>
      <g transform="translate(1113.609)">
        <rect
          className="skct__a"
          width={359.563}
          height={242.106}
          rx={51.632}
        />
        <rect
          className="skct__c"
          width={327.47}
          height={188.61}
          rx={40.697}
          transform="translate(15.756 38.946)"
        />
        <circle
          className="skct__c"
          cx={6.835}
          cy={6.835}
          r={6.835}
          transform="translate(34.536 14.292)"
        />
        <circle
          className="skct__d"
          cx={6.835}
          cy={6.835}
          r={6.835}
          transform="translate(57.973 14.292)"
        />
        <circle
          className="skct__e"
          cx={6.835}
          cy={6.835}
          r={6.835}
          transform="translate(81.411 14.292)"
        />
        <text
          transform="translate(33.364 92.179)"
          fontSize={38}
          fontFamily="JetBrainsMono-Bold,JetBrains Mono"
          fontWeight={700}
          fill="#7cdd8a"
        >
          <tspan x={0} y={0}>
            {"/HTML"}
          </tspan>
        </text>
      </g>
      <g transform="translate(1551.252 655.526)">
        <rect
          className="skct__a"
          width={359.563}
          height={242.106}
          rx={51.632}
        />
        <rect
          className="skct__b"
          width={327.47}
          height={188.61}
          rx={40.697}
          transform="translate(15.756 38.946)"
        />
        <circle
          className="skct__c"
          cx={6.835}
          cy={6.835}
          r={6.835}
          transform="translate(34.536 14.292)"
        />
        <circle
          className="skct__d"
          cx={6.835}
          cy={6.835}
          r={6.835}
          transform="translate(57.974 14.292)"
        />
        <circle
          className="skct__e"
          cx={6.835}
          cy={6.835}
          r={6.835}
          transform="translate(81.411 14.292)"
        />
        <text className="skct__h" transform="translate(33.364 96.397)">
          <tspan x={0} y={0}>
            {"/HTML"}
          </tspan>
        </text>
      </g>
      <g transform="translate(1220.72 361.754)">
        <rect
          className="skct__a"
          width={314.884}
          height={210.527}
          rx={45.057}
        />
        <rect
          className="skct__d"
          width={286.779}
          height={158.683}
          rx={34.933}
          transform="translate(13.799 39.192)"
        />
        <circle
          className="skct__c"
          cx={6.835}
          cy={6.835}
          r={6.835}
          transform="translate(34.536 14.292)"
        />
        <circle
          className="skct__d"
          cx={6.835}
          cy={6.835}
          r={6.835}
          transform="translate(57.974 14.292)"
        />
        <circle
          className="skct__e"
          cx={6.835}
          cy={6.835}
          r={6.835}
          transform="translate(81.411 14.292)"
        />
        <text
          transform="translate(33.364 94.288)"
          fill="#e5309c"
          fontSize={38}
          fontFamily="JetBrainsMono-Bold,JetBrains Mono"
          fontWeight={700}
        >
          <tspan x={0} y={0}>
            {"/CSS"}
          </tspan>
        </text>
      </g>
      <g transform="translate(1097.814 813.593)">
        <rect
          className="skct__a"
          width={314.884}
          height={210.527}
          rx={45.057}
        />
        <rect
          className="skct__c"
          width={286.779}
          height={158.683}
          rx={34.933}
          transform="translate(13.798 39.192)"
        />
        <circle
          className="skct__c"
          cx={6.835}
          cy={6.835}
          r={6.835}
          transform="translate(34.536 14.292)"
        />
        <circle
          className="skct__d"
          cx={6.835}
          cy={6.835}
          r={6.835}
          transform="translate(57.973 14.292)"
        />
        <circle
          className="skct__e"
          cx={6.835}
          cy={6.835}
          r={6.835}
          transform="translate(81.411 14.292)"
        />
        <text
          transform="translate(33.364 93.991)"
          fontFamily="JetBrainsMono-Bold,JetBrains Mono"
          fontWeight={700}
          fontSize={37}
          fill="#ffea6c"
        >
          <tspan x={0} y={0}>
            {"/Python"}
          </tspan>
        </text>
      </g>
      <g transform="translate(74.3 715.158)">
        <rect
          className="skct__a"
          width={314.884}
          height={210.527}
          rx={45.057}
        />
        <rect
          className="skct__c"
          width={286.779}
          height={158.683}
          rx={34.933}
          transform="translate(13.798 39.192)"
        />
        <circle
          className="skct__c"
          cx={6.835}
          cy={6.835}
          r={6.835}
          transform="translate(34.536 14.292)"
        />
        <circle
          className="skct__d"
          cx={6.835}
          cy={6.835}
          r={6.835}
          transform="translate(57.974 14.292)"
        />
        <circle
          className="skct__e"
          cx={6.835}
          cy={6.835}
          r={6.835}
          transform="translate(81.411 14.292)"
        />
        <text className="skct__k" transform="translate(33.364 92.179)">
          <tspan x={0} y={0}>
            {"/CSS"}
          </tspan>
        </text>
      </g>
      <g transform="translate(0 229.737)">
        <rect
          className="skct__a"
          width={314.884}
          height={210.527}
          rx={45.057}
        />
        <rect
          className="skct__c"
          width={286.779}
          height={158.683}
          rx={34.933}
          transform="translate(13.799 39.192)"
        />
        <circle
          className="skct__c"
          cx={6.835}
          cy={6.835}
          r={6.835}
          transform="translate(34.536 14.292)"
        />
        <circle
          className="skct__d"
          cx={6.835}
          cy={6.835}
          r={6.835}
          transform="translate(57.974 14.292)"
        />
        <circle
          className="skct__e"
          cx={6.835}
          cy={6.835}
          r={6.835}
          transform="translate(81.411 14.292)"
        />
        <text className="skct__k" transform="translate(33.364 87.233)">
          <tspan x={0} y={0}>
            {"/CSS"}
          </tspan>
        </text>
      </g>
      <g transform="translate(386.963 435.496)">
        <rect
          className="skct__a"
          width={314.884}
          height={210.527}
          rx={45.057}
        />
        <rect
          className="skct__d"
          width={286.779}
          height={158.683}
          rx={34.933}
          transform="translate(13.799 39.192)"
        />
        <circle
          className="skct__c"
          cx={6.835}
          cy={6.835}
          r={6.835}
          transform="translate(34.536 14.292)"
        />
        <circle
          className="skct__d"
          cx={6.835}
          cy={6.835}
          r={6.835}
          transform="translate(57.974 14.292)"
        />
        <circle
          className="skct__e"
          cx={6.835}
          cy={6.835}
          r={6.835}
          transform="translate(81.411 14.292)"
        />
        <text className="skct__f" transform="translate(33.364 91.72)">
          <tspan x={0} y={0}>
            {"/NodeJS"}
          </tspan>
        </text>
      </g>
      <g transform="translate(94.691 1162.785)">
        <rect
          className="skct__a"
          width={314.884}
          height={210.527}
          rx={45.057}
        />
        <rect
          className="skct__b"
          width={286.779}
          height={158.683}
          rx={34.933}
          transform="translate(13.799 39.192)"
        />
        <circle
          className="skct__c"
          cx={6.835}
          cy={6.835}
          r={6.835}
          transform="translate(34.536 14.292)"
        />
        <circle
          className="skct__d"
          cx={6.835}
          cy={6.835}
          r={6.835}
          transform="translate(57.974 14.292)"
        />
        <circle
          className="skct__e"
          cx={6.835}
          cy={6.835}
          r={6.835}
          transform="translate(81.411 14.292)"
        />
        <text className="skct__h" transform="translate(33.364 92.882)">
          <tspan x={0} y={0}>
            {"/CSS"}
          </tspan>
        </text>
      </g>
      <g transform="translate(776.624 149.407)">
        <rect
          className="skct__a"
          width={289.891}
          height={190.702}
          rx={41.146}
        />
        <rect
          className="skct__l"
          width={264.016}
          height={139.003}
          rx={31.371}
          transform="translate(12.703 40.238)"
        />
        <circle
          className="skct__c"
          cx={6.835}
          cy={6.835}
          r={6.835}
          transform="translate(34.536 14.292)"
        />
        <circle
          className="skct__d"
          cx={6.835}
          cy={6.835}
          r={6.835}
          transform="translate(57.974 14.292)"
        />
        <circle
          className="skct__e"
          cx={6.835}
          cy={6.835}
          r={6.835}
          transform="translate(81.411 14.292)"
        />
        <text className="skct__h" transform="translate(33.364 94.449)">
          <tspan x={0} y={0}>
            {"/SQL"}
          </tspan>
        </text>
      </g>
      <g transform="translate(520.097 858.387)">
        <rect
          className="skct__a"
          width={289.891}
          height={190.702}
          rx={41.146}
        />
        <rect
          className="skct__l"
          width={264.016}
          height={139.003}
          rx={31.371}
          transform="translate(12.703 40.238)"
        />
        <circle
          className="skct__c"
          cx={6.835}
          cy={6.835}
          r={6.835}
          transform="translate(34.536 14.292)"
        />
        <circle
          className="skct__d"
          cx={6.835}
          cy={6.835}
          r={6.835}
          transform="translate(57.974 14.292)"
        />
        <circle
          className="skct__e"
          cx={6.835}
          cy={6.835}
          r={6.835}
          transform="translate(81.411 14.292)"
        />
        <text className="skct__h" transform="translate(33.364 93.832)">
          <tspan x={0} y={0}>
            {"/Swift"}
          </tspan>
        </text>
      </g>
      <g transform="translate(1551.252 97.555)">
        <rect
          className="skct__a"
          width={289.891}
          height={190.702}
          rx={41.146}
        />
        <rect
          className="skct__l"
          width={264.016}
          height={139.003}
          rx={31.371}
          transform="translate(12.703 40.238)"
        />
        <circle
          className="skct__c"
          cx={6.835}
          cy={6.835}
          r={6.835}
          transform="translate(34.536 14.292)"
        />
        <circle
          className="skct__d"
          cx={6.835}
          cy={6.835}
          r={6.835}
          transform="translate(57.974 14.292)"
        />
        <circle
          className="skct__e"
          cx={6.835}
          cy={6.835}
          r={6.835}
          transform="translate(81.411 14.292)"
        />
        <text className="skct__h" transform="translate(33.364 93.832)">
          <tspan x={0} y={0}>
            {"/Java"}
          </tspan>
        </text>
      </g>
    </svg>
  );
}

export default SkillCoursesTablet;
