import * as React from "react";

function WeOfferIconsMobile() {
  return (
    <svg width={542.749} height={808.329} viewBox="0 0 542.749 808.329">
      <defs>
        <style>
          {
            ".woim__a,.woim__d{fill:#7cdd8a}.woim__a,.woim__e{font-size:23px;font-family:Gilroy-ExtraBold,Gilroy;font-weight:800}.woim__b{fill:#7d1cc9}.woim__c,.woim__e{fill:#ffea6c}.woim__f{fill:#960863}.woim__g{fill:#ef2996}.woim__h{fill:#6498ed}.woim__i{fill:#99e7ff}"
          }
        </style>
      </defs>
      <text className="woim__a" transform="translate(82.144 22)">
        <tspan x={0} y={0}>
          {"A future"}
        </tspan>
        <tspan x={0} y={27.6}>
          {"proof skill"}
        </tspan>
      </text>
      <path
        className="woim__b"
        d="M126.117 62.567c.008-.21 0-.417.008-.626L93.67 60.759c0 .211.013.415.005.627-.526 14.443-11.374 25.174-27.152 24.6s-25.816-12.065-25.29-26.508c.521-14.311 11.181-24.959 26.721-24.6l1.214-33.34C36.26.567 9.974 25.77 8.79 58.293c-1.189 32.665 23.41 59.816 56.52 61.022s59.618-24.083 60.807-56.748z"
      />
      <path
        className="woim__c"
        d="M.001 84.876l23.9 10.938v-8.932L10.55 80.897l13.351-5.894v-8.946L.001 77.049z"
      />
      <path className="woim__d" d="M28.135 102.871h9.47L54.922 56.23h-9.42z" />
      <path
        className="woim__c"
        d="M59.154 66.106v8.938l13.351 5.938-13.351 5.939v8.892l23.9-10.938v-7.832z"
      />
      <text className="woim__e" transform="translate(263.729 160.908)">
        <tspan x={-41.503} y={0}>
          {"Reports"}
        </tspan>
      </text>
      <path
        className="woim__f"
        d="M260.15 129.97c0 .21.017.416.021.625l32.475-.294c-.006-.21-.032-.413-.034-.625-.13-14.452 10.22-25.665 26.007-25.807s26.337 10.88 26.468 25.332c.13 14.319-10.036 25.441-25.577 25.785l.3 33.361c32.918-.525 58.033-26.9 57.738-59.439-.295-32.686-26.1-58.691-59.233-58.392s-58.461 26.766-58.165 59.454z"
      />
      <path
        className="woim__c"
        d="M350.026 69.782l-8.377 31.265 29.2-7.823a32.557 32.557 0 00-20.823-23.442z"
      />
      <path
        className="woim__d"
        d="M332.767 109.955l8.415-31.4a32.507 32.507 0 00-8.415-1.1 32.507 32.507 0 00-32.507 32.507 32.507 32.507 0 0032.507 32.508 32.507 32.507 0 0032.508-32.508 32.523 32.523 0 00-1.1-8.415z"
      />
      <g transform="translate(1170.195 -4544.318)">
        <text className="woim__e" transform="translate(-1132.46 4905.31)">
          <tspan x={0} y={0}>
            {"Learning"}
          </tspan>
          <tspan x={0} y={27.6}>
            {" is fun!"}
          </tspan>
        </text>
        <path
          className="woim__g"
          d="M-1089.1 4876.36c0 .21.008.416.008.626l32.474.4c0-.211-.023-.414-.021-.626.179-14.452 10.766-25.44 26.553-25.245s26.1 11.441 25.92 25.893c-.177 14.319-10.578 25.221-26.122 25.232l-.412 33.36c32.922.18 58.594-25.647 59-58.19.4-32.684-24.841-59.236-57.97-59.645s-59.024 25.511-59.43 58.195z"
        />
        <path
          className="woim__h"
          d="M-1008.222 4860.076v30.685l36.518-22.292h.007v-30.693z"
        />
        <path
          className="woim__i"
          d="M-1019.618 4853.122l-7.023-4.29-18.114-11.057v30.693h.007l36.525 22.292v-30.685z"
        />
        <path
          d="M-991.845 4825.474l-16.377-10-17.346 10.586-19.186 11.713 18.114 11.057 7.023 4.29 11.4 6.954 36.525-22.3z"
          fill="#78c2f9"
        />
        <ellipse
          className="woim__h"
          cx={8.045}
          cy={3.713}
          rx={8.045}
          ry={3.713}
          transform="translate(-1035.144 4833.959)"
        />
        <path
          className="woim__h"
          d="M-1035.144 4824.816h16.09v12.856h-16.09z"
        />
        <ellipse
          className="woim__i"
          cx={8.045}
          cy={3.713}
          rx={8.045}
          ry={3.713}
          transform="translate(-1035.144 4821.103)"
        />
        <ellipse
          className="woim__h"
          cx={8.045}
          cy={3.713}
          rx={8.045}
          ry={3.713}
          transform="translate(-1016.267 4845.331)"
        />
        <path
          className="woim__h"
          d="M-1016.267 4836.187h16.09v12.856h-16.09z"
        />
        <ellipse
          className="woim__i"
          cx={8.045}
          cy={3.713}
          rx={8.045}
          ry={3.713}
          transform="translate(-1016.267 4832.474)"
        />
        <ellipse
          className="woim__h"
          cx={8.045}
          cy={3.713}
          rx={8.045}
          ry={3.713}
          transform="translate(-1016.267 4823.818)"
        />
        <path
          className="woim__h"
          d="M-1016.267 4814.674h16.09v12.856h-16.09z"
        />
        <ellipse
          className="woim__i"
          cx={8.045}
          cy={3.713}
          rx={8.045}
          ry={3.713}
          transform="translate(-1016.267 4810.961)"
        />
        <ellipse
          className="woim__h"
          cx={8.045}
          cy={3.713}
          rx={8.045}
          ry={3.713}
          transform="translate(-997.24 4833.959)"
        />
        <path className="woim__h" d="M-997.24 4824.816h16.09v12.856h-16.09z" />
        <ellipse
          className="woim__i"
          cx={8.045}
          cy={3.713}
          rx={8.045}
          ry={3.713}
          transform="translate(-997.24 4821.103)"
        />
      </g>
      <text className="woim__e" transform="translate(367.907 324.454)">
        <tspan x={-41.032} y={0}>
          {"Ad Free"}
        </tspan>
      </text>
      <path
        className="woim__f"
        d="M360.174 290.555c-.007.21 0 .416-.007.626l32.455 1.181c0-.21-.013-.415-.006-.626.526-14.443 11.375-25.174 27.152-24.6s25.816 12.066 25.29 26.509c-.52 14.31-11.18 24.959-26.721 24.6l-1.214 33.341c32.909.97 59.194-24.232 60.379-56.756 1.189-32.665-23.411-59.816-56.52-61.021s-59.619 24.081-60.808 56.746z"
      />
      <path
        className="woim__g"
        d="M380.23 274.1a24.3 24.3 0 0124.272-24.272 24.3 24.3 0 0124.272 24.272 24.3 24.3 0 01-24.272 24.271A24.3 24.3 0 01380.23 274.1zm7.383 0a16.908 16.908 0 0016.889 16.888 16.908 16.908 0 0016.889-16.888 16.908 16.908 0 00-16.889-16.889 16.908 16.908 0 00-16.889 16.889z"
      />
      <path
        className="woim__g"
        d="M389.097 263.914l5.22-5.22 25.586 25.587-5.22 5.22z"
      />
      <path
        className="woim__d"
        d="M437.695 262.757l-11.9 4.495-3.824 1.444a6.177 6.177 0 00-3.912 4.779l-1.853 11.307a9.264 9.264 0 003.274 8.666l8.865 7.257a6.176 6.176 0 006.095 1l3.825-1.445 11.9-4.494 43.043-16.26a6.177 6.177 0 003.595-7.96l-8.1-21.453a6.176 6.176 0 00-7.96-3.6z"
      />
      <text
        transform="rotate(-20.695 1016.466 -1036.98)"
        fontSize={24.379}
        fontFamily="JetBrainsMono-Bold,JetBrains Mono"
        fontWeight={700}
        fill="#ef2996"
      >
        <tspan x={0} y={0}>
          {"FREE"}
        </tspan>
      </text>
      <g>
        <text className="woim__a" transform="translate(351.785 460.45)">
          <tspan x={0} y={0}>
            {"CodeLabb"}
          </tspan>
          <tspan x={0} y={27.6}>
            {"Certificate"}
          </tspan>
        </text>
        <path
          d="M396.778 503.023c0-.21-.014-.417-.016-.626l-32.477.047c0 .21.029.414.029.625.021 14.453-10.414 25.586-26.2 25.609s-26.253-11.08-26.274-25.533c-.021-14.32 10.229-25.364 25.772-25.589l-.048-33.363c-32.922.275-58.236 26.453-58.189 59 .047 32.686 25.656 58.887 58.787 58.84s58.663-26.324 58.616-59.01z"
          fill="#d81e8d"
        />
        <path
          className="woim__d"
          d="M335.261 572.995l-10.309-4.526-4.892 10.14-17.621-47.708 15.2-5.614z"
        />
        <path
          className="woim__d"
          d="M278.003 572.995l10.309-4.526 4.891 10.14 17.622-47.708-15.2-5.614z"
        />
        <path
          className="woim__c"
          d="M310.346 487.407a5.8 5.8 0 019.156 2.454 5.8 5.8 0 006.433 3.714 5.8 5.8 0 016.7 6.7 5.8 5.8 0 003.714 6.432 5.8 5.8 0 012.453 9.157 5.8 5.8 0 000 7.428 5.8 5.8 0 01-2.453 9.156 5.8 5.8 0 00-3.714 6.433 5.8 5.8 0 01-6.7 6.7 5.8 5.8 0 00-6.433 3.714 5.8 5.8 0 01-9.156 2.453 5.8 5.8 0 00-7.428 0 5.8 5.8 0 01-9.156-2.453 5.8 5.8 0 00-6.433-3.714 5.8 5.8 0 01-6.7-6.7 5.8 5.8 0 00-3.714-6.433 5.8 5.8 0 01-2.453-9.156 5.8 5.8 0 000-7.428 5.8 5.8 0 012.453-9.157 5.8 5.8 0 003.714-6.432 5.8 5.8 0 016.7-6.7 5.8 5.8 0 006.433-3.714 5.8 5.8 0 019.156-2.454 5.8 5.8 0 007.428 0z"
        />
      </g>
      <g>
        <text className="woim__a" transform="translate(436.75 774.329)">
          <tspan x={0} y={0}>
            {"Adult and"}
          </tspan>
          <tspan x={0} y={27.6}>
            {"Bully Free"}
          </tspan>
        </text>
        <path
          className="woim__b"
          d="M426.552 805.059c.21.008.417 0 .626.008l1.182-32.456c-.211 0-.415.014-.627.006-14.443-.526-25.174-11.375-24.6-27.152s12.065-25.816 26.508-25.29c14.311.521 24.959 11.181 24.6 26.721l33.34 1.214c.971-32.908-24.232-59.194-56.756-60.378-32.664-1.189-59.815 23.41-61.021 56.52s24.083 59.618 56.748 60.807z"
        />
        <path
          className="woim__c"
          d="M413.905 757.02l-1.818-.946c-22.894-11.906-33.321-29.865-33.321-41.513v-34.795h3.94c14.762 0 22.243-1.99 28.588-7.6l2.611-2.31 2.611 2.31c6.345 5.614 13.826 7.6 28.587 7.6h3.941v34.795c0 11.648-10.427 29.607-33.321 41.513zm-27.258-69.425v26.966c0 7.572 7.3 22.528 27.258 33.547 19.956-11.019 27.257-25.975 27.257-33.547v-26.966c-12.611-.353-20.576-2.507-27.257-7.418-6.682 4.911-14.646 7.065-27.258 7.418z"
        />
        <path
          d="M429.858 706.294l-13.42 13.419a9.721 9.721 0 01-13.734 0l-4.752-4.753 5.626-5.626 4.753 4.753a1.756 1.756 0 002.481 0l13.42-13.42z"
          fill="#69ed82"
        />
      </g>
    </svg>
  );
}

export default WeOfferIconsMobile;
