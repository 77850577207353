import { useMediaQuery } from "react-responsive";

const Desktop = ({ children }: { children: JSX.Element }) => {
  const isDesktop = useMediaQuery({ minWidth: 1366 });
  return isDesktop ? children : null;
};
const Tablet = ({ children }: { children: JSX.Element }) => {
  const isTablet = useMediaQuery({ minWidth: 992, maxWidth: 1365 });
  return isTablet ? children : null;
};
const Mobile = ({ children }: { children: JSX.Element }) => {
  const isMobile = useMediaQuery({ minWidth: 0, maxWidth: 991 });
  return isMobile ? children : null;
};

export { Desktop, Tablet, Mobile };
