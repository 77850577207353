import React from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import "./Header.scss";
import LoginButton from "./LoginButton";

function Header() {
  return (
    <div id="Header">
      <a href="/" title="Codelabb">
        <img src="/media/common/space.png" alt="" />
      </a>
      <nav>
        <a href="/" className="btn-mob-menu">
          <img src="/media/common/hamburger.svg" alt="Menu" />
        </a>

        <a href="/" className="lnk-courses">
          Courses
        </a>
        <a href="/" className="lnk-skills">
          Skills
        </a>
        <a href="/" className="lnk-projects">
          Projects
        </a>
        <a href="/" className="lnk-redeem-code">
          Redeem Code
        </a>
        {/* Adult Login */}
        <Auth0Provider
          domain="codelabb-dev.eu.auth0.com"
          clientId="3Zg4L8LkJnvXm19PLUqtR6RBpvH3bjYK"
          redirectUri="http://localhost:3000/adults/"
        >
          <LoginButton label="Adult Sign In" className="btn-adult-login" />
        </Auth0Provider>
        {/* Kid Login */}
        <Auth0Provider
          domain="codelabb-dev.eu.auth0.com"
          clientId="K63LcRfnfpoGyuyKeuQSl8EFzh48DzZO"
          redirectUri="http://localhost:3000/kids/"
        >
          <LoginButton label="Kid Sign In" className="btn-kid-login" />
        </Auth0Provider>
      </nav>
    </div>
  );
}

export default Header;
