import React from "react";
import "./Badges.scss";

function Badges() {
  return (
    <section id="Badges">
      <div className="bdg-container">
        <div className="bdg-title-container">
          <h3>
            Earn a <span className="highlight"> Digital</span>
            <span className="highlight-bg-blue">Badge</span>
            <br />
            for Each Achievement
          </h3>
        </div>
        <div className="bdg-badges"></div>
      </div>
    </section>
  );
}

export default Badges;
