import React from "react";
import "./TopReview.scss";

function TopReview() {
  return (
    <section id="TopReview">
      <div className="tr-container">
        <div className="tr-content">
          <div className="tr-smiley bold-font">=D</div>
          <div className="tr-rating">
            <div className="tr-stars">
              <i className="fa fa-star" aria-hidden="true"></i>
              <i className="fa fa-star" aria-hidden="true"></i>
              <i className="fa fa-star" aria-hidden="true"></i>
              <i className="fa fa-star" aria-hidden="true"></i>
              <i className="fa fa-star" aria-hidden="true"></i>
            </div>
            <div className="tr-who">
              <p className="bold-font">Sam T.</p>
              <span>Dad from Pennsylvania</span>
            </div>
          </div>
          <p className="bold-font">
            Ready? Get set... <strong>code!</strong>
          </p>
        </div>
      </div>
    </section>
  );
}

export default TopReview;
