import React from "react";
import "./AboutUsVideo.scss";

function AboutUsVideo() {
  return (
    <section id="AboutUsVideo">
      <div className="au-container">
        <div className="au-bubble">
          <div className="au-bubble-content bold-font">
            <p>
              Here is <strong>why kids enjoy</strong>
            </p>
            <p>learning at =D CodeLabb</p>
          </div>
        </div>
        <div className="au-video-container">
          <div className="au-rico"></div>
          <div className="au-video"></div>
        </div>
      </div>
    </section>
  );
}

export default AboutUsVideo;
