import React from "react";
import "./Home.scss";
import Header from "../common/components/Header";
import TopTeaser from "./components/TopTeaser";
import TopReview from "./components/TopReview";
import AboutUsVideo from "./components/AboutUsVideo";
import Skills from "./components/Skills";
import Badges from "./components/Badges";
import WeOffer from "./components/WeOffer";
import StartFreeTrialTeaser from "./components/StartFreeTrialTeaser";
import ChangeTheWorldTeaser from "./components/ChangeTheWorldTeaser";
import CustomerReviews from "./components/CustomerReviews";
import { Tablet, Desktop } from "../common/components/MediaQueries";
import LearnFunShareTeaser from "./components/LearnFunShareTeaser";
import CodingCoursesBullets from "./components/CodingCoursesBullets";
import Footer from "../common/components/Footer";
import KeepInformed from "./components/KeepInformed";
import MeetOurTeam from "./components/MeetOurTeam";

function Home() {
  return (
    <div id="Home">
      <div className="home-container">
        <header>
          <Header />
        </header>
        <main>
          <TopTeaser />

          {/* TopReview: only for tablet and desktop - START */}
          <Tablet>
            <TopReview />
          </Tablet>
          <Desktop>
            <TopReview />
          </Desktop>
          {/* TopReview: only for tablet and desktop - END */}

          <AboutUsVideo />
          <Skills />
          <Badges />
          <WeOffer />

          {/* ChangeTheWorldTeaser: only for tablet and desktop - START */}
          <Tablet>
            <ChangeTheWorldTeaser />
          </Tablet>
          <Desktop>
            <ChangeTheWorldTeaser />
          </Desktop>
          {/* ChangeTheWorldTeaser: only for tablet and desktop - END */}

          <StartFreeTrialTeaser />

          {/* CustomerReviews: only for tablet and desktop - START */}
          <Tablet>
            <CustomerReviews />
          </Tablet>
          <Desktop>
            <CustomerReviews />
          </Desktop>
          {/* CustomerReviews: only for tablet and desktop - END */}

          <LearnFunShareTeaser />
          <CodingCoursesBullets />

          {/* KeepInformed: only for tablet and desktop - START */}
          <Tablet>
            <KeepInformed />
          </Tablet>
          <Desktop>
            <KeepInformed />
          </Desktop>
          {/* KeepInformed: only for tablet and desktop - END */}

          {/* MeetOurTeam: only for tablet and desktop - START */}
          <Tablet>
            <MeetOurTeam />
          </Tablet>
          <Desktop>
            <MeetOurTeam />
          </Desktop>
          {/* MeetOurTeam: only for tablet and desktop - END */}
        </main>
        <footer>
          <Footer />
        </footer>
      </div>
    </div>
  );
}

export default Home;
