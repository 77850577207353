import React from "react";
import Home from "./home/Home";
import "./App.scss";

function App() {
  return (
    <div id="App">
      <div className="app-container">
        <Home />
      </div>
    </div>
  );
}

export default App;
