import React from "react";
import "./CustomerReviews.scss";
import CustomerReview from "./CustomerReview";

function CustomerReviews() {
  return (
    <section id="CustomerReviews">
      <div className="crs-container">
        <CustomerReview
          name="Matt T.J."
          from="Dad from Orlando"
          review="Awesome, my son has learnt so much in such a short time!"
          smiley="=D"
        ></CustomerReview>
        <CustomerReview
          name="Kerry W."
          from="Mom from Denver"
          review="Thanks so much! Emma loves coding now."
          smiley=":-)"
        ></CustomerReview>
        <CustomerReview
          name="Vanessa W."
          from="Mom from London"
          review="Woohoo, my kid has started to develop his own game!"
          smiley=";D"
        ></CustomerReview>
      </div>
    </section>
  );
}

export default CustomerReviews;
