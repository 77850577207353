import React from "react";
import "./LearnFunShareTeaser.scss";

function LearnFunShareTeaser() {
  return (
    <section id="LearnFunShareTeaser">
      <div className="lfst-container">
        <div className="lfst-title-container">
          <h3>
            <span>
              <strong className="highlight">Learn. </strong>
            </span>
            <span>
              Have <strong className="highlight">Fun.</strong>{" "}
            </span>
            <span className="highlight-bg-green">
              <strong className="body-text ">And Share</strong>{" "}
              <i className="highlight">=D.</i>
            </span>
          </h3>
        </div>
        <div className="lfst-info">
          <p>
            Kids love to share what they have learnt and created with other
            kids. At CodeLabb we offer them a
            <strong> platform for exchanging ideas</strong>, giving feedback and
            helping each other to solve code challenges.
          </p>
          <br />
          <p>
            Apart from our moderators, who make sure that the community remains
            pleasant and safe, our community is
            <strong> strictly for kids only</strong>.
          </p>
        </div>
      </div>
    </section>
  );
}

export default LearnFunShareTeaser;
