import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LoginButton = (props: { label: string; className: string }) => {
  const { loginWithRedirect } = useAuth0();

  return (
    <button
      className={"test " + props.className}
      onClick={() => loginWithRedirect()}
    >
      {props.label}
    </button>
  );
};

export default LoginButton;
