import React from "react";
import "./WeOffer.scss";
import { Mobile, Tablet, Desktop } from "../../common/components/MediaQueries";
import WeOfferIconsMobile from "./WeOfferIconsMobile";
import WeOfferIconsTablet from "./WeOfferIconsTablet";
import WeOfferIconsDesktop from "./WeOfferIconsDesktop";

function WeOffer() {
  return (
    <section id="WeOffer">
      <div className="wof-container">
        <div className="wof-title-container">
          <h3>
            We<span className="highlight-bg-yellow">Offer</span>
          </h3>
        </div>
        <div className="wof-icons">
          <Mobile>
            <WeOfferIconsMobile />
          </Mobile>
          <Tablet>
            <WeOfferIconsTablet />
          </Tablet>
          <Desktop>
            <WeOfferIconsDesktop />
          </Desktop>
        </div>
      </div>
    </section>
  );
}

export default WeOffer;
