import React from "react";
import "./Footer.scss";

function Footer() {
  return (
    <div id="Footer">
      <div className="footer-top"></div>
      <div className="footer-bottom">
        <a href="/" title="Codelabb">
          <img src="/media/common/space.png" alt="" />
        </a>
        <div className="footer-links">
          <a href="/courses">Courses</a>
          <a href="/courses">Skills</a>
          <a href="/courses">Projects</a>
          <a href="/courses">Redeem Code</a>
          <a href="/courses">Adult Sign In</a>
        </div>
        <div className="footer-socialmedia-icons">
          <a href="/" title="">
            <img src="/media/common/footer-icon-instagram.svg" alt="" />
          </a>
          <a href="/" title="">
            <img src="/media/common/footer-icon-facebook.svg" alt="" />
          </a>
          <a href="/" title="">
            <img src="/media/common/footer-icon-twitter.svg" alt="" />
          </a>
          <a href="/" title="">
            <img src="/media/common/footer-icon-youtube.svg" alt="" />
          </a>
        </div>
        <div className="footer-copyright">
          © 2020 CodeLabb. All rights reserved.
        </div>
      </div>
    </div>
  );
}

export default Footer;
