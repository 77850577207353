import React from "react";
import "./Skills.scss";
import { Mobile, Tablet, Desktop } from "../../common/components/MediaQueries";
import SkillCoursesMobile from "./SkillCoursesMobile";
import SkillCoursesTablet from "./SkillCoursesTablet";
import SkillCoursesDesktop from "./SkillCoursesDesktop";

function Skills() {
  return (
    <section id="Skills">
      <div className="sk-container">
        <div className="sk-buttons">
          <button className="btn-free">Try for FREE</button>
        </div>
        <div className="sk-title-container">
          <h3>
            Learn a <span className="highlight">Variety of</span>
            <strong className="highlight-bg">Programming</strong> Languages for
            <span className="highlight">
              &nbsp;any
              <br />
              Skill Level
            </span>
          </h3>
        </div>
        <div className="sk-courses-svg">
          <Mobile>
            <SkillCoursesMobile />
          </Mobile>
          <Tablet>
            <SkillCoursesTablet />
          </Tablet>
          <Desktop>
            <SkillCoursesDesktop />
          </Desktop>
        </div>
      </div>
    </section>
  );
}

export default Skills;
