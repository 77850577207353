import * as React from "react";

function SkillCoursesMobile() {
  return (
    <svg width={563.777} height={1324.643} viewBox="0 0 563.777 1324.643">
      <defs>
        <style>
          {
            ".skcm__a,.skcm__i{fill:#410e7c}.skcm__c{fill:#ef2996}.skcm__d{fill:#ffea6c}.skcm__e{fill:#99e7ff}.skcm__i{font-family:JetBrainsMono-Bold,JetBrains Mono;font-weight:700}.skcm__h{fill:#78c2f9}.skcm__i{font-size:39.714px}"
          }
        </style>
      </defs>
      <g transform="translate(1184.161 -1143.026)">
        <rect
          className="skcm__a"
          width={239.705}
          height={161.402}
          rx={27.059}
          transform="translate(-1177.793 1212.44)"
        />
        <rect
          width={218.31}
          height={125.738}
          rx={21.328}
          transform="translate(-1167.289 1238.403)"
          fill="#7cdd8a"
        />
        <circle
          className="skcm__c"
          cx={4.557}
          cy={4.557}
          r={4.557}
          transform="translate(-1154.769 1221.967)"
        />
        <circle
          className="skcm__d"
          cx={4.557}
          cy={4.557}
          r={4.557}
          transform="translate(-1139.144 1221.967)"
        />
        <circle
          className="skcm__e"
          cx={4.557}
          cy={4.557}
          r={4.557}
          transform="translate(-1123.519 1221.967)"
        />
        <text
          transform="translate(-1155.027 1279.038)"
          fontFamily="JetBrainsMono-Bold,JetBrains Mono"
          fontWeight={700}
          fontSize={26.203}
          fill="#410e7c"
        >
          <tspan x={0} y={0}>
            {"/Java"}
          </tspan>
          <tspan x={0} y={30.495}>
            {" Script"}
          </tspan>
        </text>
      </g>
      <g transform="translate(1184.161 -1143.026)">
        <rect
          className="skcm__a"
          width={399.923}
          height={269.282}
          rx={45.144}
          transform="translate(-1037.498 1392.875)"
        />
        <rect
          className="skcm__c"
          width={364.228}
          height={209.781}
          rx={35.583}
          transform="translate(-1019.973 1436.193)"
        />
        <circle
          className="skcm__c"
          cx={7.603}
          cy={7.603}
          r={7.603}
          transform="translate(-999.086 1408.771)"
        />
        <circle
          className="skcm__d"
          cx={7.603}
          cy={7.603}
          r={7.603}
          transform="translate(-973.017 1408.771)"
        />
        <circle
          className="skcm__e"
          cx={7.603}
          cy={7.603}
          r={7.603}
          transform="translate(-946.949 1408.771)"
        />
        <text
          transform="translate(-1000.39 1497.09)"
          fontSize={43.717}
          fontFamily="JetBrainsMono-Bold,JetBrains Mono"
          fontWeight={700}
          fill="#7cdd8a"
        >
          <tspan x={0} y={0}>
            {"/HTML"}
          </tspan>
        </text>
      </g>
      <g transform="translate(1184.161 -1143.026)">
        <rect
          className="skcm__a"
          width={292.903}
          height={192.683}
          rx={32.681}
          transform="translate(-916.734 1143.026)"
        />
        <rect
          className="skcm__h"
          width={266.76}
          height={140.447}
          rx={24.917}
          transform="translate(-903.899 1183.682)"
        />
        <circle
          className="skcm__c"
          cx={6.906}
          cy={6.906}
          r={6.906}
          transform="translate(-881.84 1157.467)"
        />
        <circle
          className="skcm__d"
          cx={6.906}
          cy={6.906}
          r={6.906}
          transform="translate(-858.158 1157.467)"
        />
        <circle
          className="skcm__e"
          cx={6.906}
          cy={6.906}
          r={6.906}
          transform="translate(-834.477 1157.467)"
        />
        <text className="skcm__i" transform="translate(-883.024 1239.992)">
          <tspan x={0} y={0}>
            {"/SQL"}
          </tspan>
        </text>
      </g>
      <g transform="translate(1184.161 -1143.026)">
        <rect
          className="skcm__a"
          width={245.243}
          height={163.966}
          rx={27.586}
          transform="translate(-1184.161 1681.19)"
        />
        <rect
          className="skcm__d"
          width={223.354}
          height={123.588}
          rx={21.388}
          transform="translate(-1173.414 1711.714)"
        />
        <circle
          className="skcm__c"
          cx={5.324}
          cy={5.324}
          r={5.324}
          transform="translate(-1157.263 1692.321)"
        />
        <circle
          className="skcm__d"
          cx={5.324}
          cy={5.324}
          r={5.324}
          transform="translate(-1139.009 1692.321)"
        />
        <circle
          className="skcm__e"
          cx={5.324}
          cy={5.324}
          r={5.324}
          transform="translate(-1120.755 1692.321)"
        />
        <text
          transform="translate(-1158.177 1755.808)"
          fill="#e5309c"
          fontSize={30.612}
          fontFamily="JetBrainsMono-Bold,JetBrains Mono"
          fontWeight={700}
        >
          <tspan x={0} y={0}>
            {"/CSS"}
          </tspan>
        </text>
      </g>
      <g transform="translate(1184.161 -1143.026)">
        <rect
          className="skcm__a"
          width={292.903}
          height={192.683}
          rx={32.681}
          transform="translate(-916.734 1702.968)"
        />
        <rect
          className="skcm__h"
          width={266.76}
          height={140.447}
          rx={24.917}
          transform="translate(-903.899 1743.624)"
        />
        <circle
          className="skcm__c"
          cx={6.906}
          cy={6.906}
          r={6.906}
          transform="translate(-881.84 1717.408)"
        />
        <circle
          className="skcm__d"
          cx={6.906}
          cy={6.906}
          r={6.906}
          transform="translate(-858.158 1717.408)"
        />
        <circle
          className="skcm__e"
          cx={6.906}
          cy={6.906}
          r={6.906}
          transform="translate(-834.477 1717.408)"
        />
        <text className="skcm__i" transform="translate(-883.024 1799.31)">
          <tspan x={0} y={0}>
            {"/Java"}
          </tspan>
        </text>
      </g>
      <g transform="translate(1184.161 -1143.026)">
        <rect
          className="skcm__a"
          width={400.561}
          height={267.81}
          rx={45.057}
          transform="translate(-1182.822 1913.851)"
        />
        <rect
          className="skcm__c"
          width={364.809}
          height={201.859}
          rx={34.933}
          transform="translate(-1165.269 1963.707)"
        />
        <circle
          className="skcm__c"
          cx={8.695}
          cy={8.695}
          r={8.695}
          transform="translate(-1138.89 1932.032)"
        />
        <circle
          className="skcm__d"
          cx={8.695}
          cy={8.695}
          r={8.695}
          transform="translate(-1109.075 1932.032)"
        />
        <circle
          className="skcm__e"
          cx={8.695}
          cy={8.695}
          r={8.695}
          transform="translate(-1079.26 1932.032)"
        />
        <text
          transform="translate(-1140.381 2036.621)"
          fontSize={50}
          fontFamily="JetBrainsMono-Bold,JetBrains Mono"
          fontWeight={700}
          fill="#ffea6c"
        >
          <tspan x={0} y={0}>
            {"/Python"}
          </tspan>
        </text>
      </g>
      <g transform="translate(1184.161 -1143.026)">
        <rect
          className="skcm__a"
          width={400.561}
          height={267.81}
          rx={45.057}
          transform="translate(-1020.944 2199.86)"
        />
        <rect
          className="skcm__d"
          width={364.809}
          height={201.859}
          rx={34.933}
          transform="translate(-1003.391 2249.716)"
        />
        <circle
          className="skcm__c"
          cx={8.695}
          cy={8.695}
          r={8.695}
          transform="translate(-977.012 2218.041)"
        />
        <circle
          className="skcm__d"
          cx={8.695}
          cy={8.695}
          r={8.695}
          transform="translate(-947.197 2218.041)"
        />
        <circle
          className="skcm__e"
          cx={8.695}
          cy={8.695}
          r={8.695}
          transform="translate(-917.382 2218.041)"
        />
        <text
          transform="translate(-978.503 2319.741)"
          fontSize={50}
          fontFamily="JetBrainsMono-Bold,JetBrains Mono"
          fontWeight={700}
          fill="#410e7c"
        >
          <tspan x={0} y={0}>
            {"/NodeJS"}
          </tspan>
        </text>
      </g>
    </svg>
  );
}

export default SkillCoursesMobile;
